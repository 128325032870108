@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/*
  NOTE TO DEVELOPERS:
  1rem = 16px
*/

* {
  margin: 0;
  font-family: 'Poppins', 'Helvetica', 'Arial', 'sans-serif';
}

html {
	height:100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-flex {
  flex: 1 0 auto;
}

.footer-flex {
  flex-shrink: 0;
}

.page-title {
  font-size: 3rem;
  font-weight: 600;
  padding-bottom: 3rem;
}
.page-title.center {
  text-align: center;
}

/* style our error messages like MUI `helperText` */
span.errMsg {
  display: inline-block;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin: 0.1875rem 0.875rem 0 0.875rem;
  color: #d32f2f;
}

/* remove arrow controls from number fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}