.pre-header {
  height: 0.9375rem;
  background-image: url('../images/pre-header.jpg');
}

.main-header {
  /* min-height: 20rem; */
  background-image: url('../images/header-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 4rem 0;
  margin-bottom: 3rem;
}

.navbar ul {
  float: left;
  display: block;
  font-weight: 600;
  list-style-type: none;
  padding: 0;
}

.navbar ul li {
  display: inline-block;
  font-weight: 500;
  color: white;
  font-size: 1.25rem;
  padding-right: 2rem;
}
.navbar ul li:last-of-type {
  padding: 0
}

.navbar ul li>a {
  color: inherit;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
.navbar ul li>a:hover {
  opacity: 0.7;
}

.navbar ul li>a.active {
  color: #00A8FF;
}

.tag-line {
  color: white;
  font-weight: 500;
  line-height: 1.4rem;
  font-size: 2rem;
}

@media (min-width: 56.25em) {/* MUI medium (md) Breakpoint; Assuming 1em = 16px, 900px = 56.25em */
	.navbar {
    float: right;
  }
}