/* services page */
.service-block {
  background-color: #00A8FF !important;
}

.service-block a {
  display: block;
  color: white;
  font-size: 1.25rem;
  padding: 1.25rem;
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none;
}