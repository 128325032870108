.main-footer {
  background-image: url('../images/footer-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
  margin-top: 2.5rem;
  padding: 2.5rem 0 1.25rem;
}

.heading {
  color: #051cc3;
  font-size: 1.5rem;
  font-weight: 600;
}

.footnote {
  text-align: center;
  color: #00ec85;
  font-size: 1rem;
}